module.exports = `
-¡Cómo cruje dentro de mi cuerpo! ¡Realmente hace un frío delicioso! -exclamó el hombre de nieve-. ¡Es bien verdad que el viento cortante puede infundir vida en uno! ¿Y dónde está aquel abrasador que mira con su ojo enorme?

Se refería al Sol, que en aquel momento se ponía.

-¡No me hará parpadear! Todavía aguanto firmes mis terrones.

Le servían de ojos dos pedazos triangulares de teja. La boca era un trozo de un rastrillo viejo; por eso tenía dientes.

Había nacido entre los hurras de los chiquillos, saludado con el sonar de cascabeles y el chasquear de látigos de los trineos.

Acabó de ocultarse el sol, salió la Luna, una Luna llena, redonda y grande, clara y hermosa en el aire azul.

-Otra vez ahí, y ahora sale por el otro lado -dijo el hombre de nieve. Creía que era el sol que volvía a aparecer-. Le hice perder las ganas de mirarme con su ojo desencajado. Que cuelgue ahora allá arriba enviando la luz suficiente para que yo pueda verme. Sólo quisiera saber la forma de moverme de mi sitio; me gustaría darme un paseo. Sobre todo, patinar sobre el hielo, como vi que hacían los niños. Pero en cuestión de andar soy un zoquete.

-¡Fuera, fuera! -ladró el viejo mastín. Se había vuelto algo ronco desde que no era perro de interior y no podía tumbarse junto a la estufa-. ¡Ya te enseñará el sol a correr! El año pasado vi cómo lo hacía con tu antecesor. ¡Fuera, fuera, todos fuera!

-No te entiendo, camarada -dijo el hombre de nieve-. ¿Es acaso aquél de allá arriba el que tiene que enseñarme a correr?

Se refería a la luna.

-La verdad es que corría, mientras yo lo miraba fijamente, y ahora vuelve a acercarse desde otra dirección.

-¡Tú qué sabes! -replicó el mastín-. No es de extrañar, pues hace tan poco que te amasaron. Aquello que ves allá es la Luna, y lo que se puso era el Sol. Mañana por la mañana volverá, y seguramente te enseñará a bajar corriendo hasta el foso de la muralla. Pronto va a cambiar el tiempo. Lo intuyo por lo que me duele la pata izquierda de detrás. Tendremos cambio.

«No lo entiendo -dijo para sí el hombre de nieve-, pero tengo el presentimiento de que insinúa algo desagradable. Algo me dice que aquel que me miraba tan fijamente y se marchó, al que él llama Sol, no es un amigo de quien pueda fiarme».

-¡Fuera, fuera! -volvió a ladrar el mastín, y, dando tres vueltas como un trompo, se metió a dormir en la perrera.

Efectivamente, cambió el tiempo. Por la mañana, una niebla espesa, húmeda y pegajosa, cubría toda la región. Al amanecer empezó a soplar el viento, un viento helado; el frío calaba hasta los huesos, pero ¡qué maravilloso espectáculo en cuanto salió el sol! Todos los árboles y arbustos estaban cubiertos de escarcha; parecían un bosque de blancos corales. Se habría dicho que las ramas estaban revestidas de deslumbrantes flores blancas. Las innúmeras ramillas, en verano invisibles por las hojas, destacaban ahora con toda precisión; era un encaje cegador, que brillaba en cada ramita. El abedul se movía a impulsos del viento; había vida en él, como la que en verano anima a los árboles. El espectáculo era de una magnificencia incomparable. Y ¡cómo refulgía todo, cuando salió el sol! Parecía que hubiesen espolvoreado el paisaje con polvos de diamante, y que grandes piedras preciosas brillasen sobre la capa de nieve. El centelleo hacía pensar en innúmeras lucecitas ardientes, más blancas aún que la blanca nieve.

-¡Qué incomparable belleza! -exclamó una muchacha, que salió al jardín en compañía de un joven, y se detuvo junto al hombre de nieve, desde el cual la pareja se quedó contemplando los árboles rutilantes.

-Ni en verano es tan bello el espectáculo -dijo, con ojos radiantes.

-Y entonces no se tiene un personaje como éste -añadió el joven, señalando el hombre de nieve- ¡Maravilloso!

La muchacha sonrió, y, dirigiendo un gesto con la cabeza al muñeco, se puso a bailar con su compañero en la nieve, que crujía bajo sus pies como si pisaran almidón.

-¿Quiénes eran esos dos? -preguntó el hombre de nieve al perr -. Tú que eres mas viejo que yo en la casa, ¿los conoces?

-Claro -respondió el mastín-. La de veces que ella me ha acariciado y me ha dado huesos. No le muerdo nunca.

-Pero, ¿qué hacen aquí? -preguntó el muñeco.

-Son novios -gruñó el can-. Se instalarán en una perrera a roer huesos. ¡Fuera, fuera!

-¿Son tan importantes como tú y como yo? -siguió inquiriendo el hombre de nieve.

-Son familia de los amos -explicó el perro-. Realmente saben bien pocas cosas los recién nacidos, a juzgar por ti. Yo soy viejo y tengo relaciones; conozco a todos los de la casa. Hubo un tiempo en que no tenía que estar encadenado a la intemperie. ¡Fuera, fuera!

-El frío es magnífico -respondió el hombre de nieve-. ¡Cuéntame, cuéntame! Pero no metas tanto ruido con la cadena, que me haces crujir.

-¡Fuera, fuera! -ladró el mastín-. Yo era un perrillo muy lindo, según decían. Entonces vivía en el interior del castillo, en una silla de terciopelo, o yacía sobre el regazo de la señora principal. Me besaban en el hocico y me secaban las patas con un pañuelo bordado. Me llamaban «guapísimo», «perrillo mono» y otras cosas. Pero luego pensaron que crecía demasiado, y me entregaron al ama de llaves. Fui a parar a la vivienda del sótano; desde ahí puedes verla, con el cuarto donde yo era dueño y señor, pues de verdad lo era en casa del ama. Cierto que era más reducido que arriba, pero más cómodo; no me fastidiaban los niños arrastrándome de aquí para allá. Me daban de comer tan bien como arriba y en mayor cantidad. Tenía mi propio almohadón, y además había una estufa que, en esta época precisamente, era lo mejor del mundo. Me metía debajo de ella y desaparecía del todo. ¡Oh, cuántas veces sueño con ella todavía! ¡Fuera, fuera!

-¿Tan hermosa es una estufa? -preguntó el hombre de nieve ¿Se me parece?

-Es exactamente lo contrario de ti. Es negra como el carbón, y tiene un largo cuello con un cilindro de latón. Devora leña y vomita fuego por la boca. Da gusto estar a su lado, o encima o debajo; esparce un calor de lo más agradable. Desde donde estás puedes verla a través de la ventana.

El hombre de nieve echó una mirada y vio, en efecto, un objeto negro y brillante, con una campana de latón. El fuego se proyectaba hacia fuera, desde el suelo. El hombre experimentó una impresión rara; no era capaz de explicársela. Le sacudió el cuerpo algo que no conocía, pero que conocen muy bien todos los seres humanos que no son muñecos de nieve.

-¿Y por qué la abandonaste? -preguntó el hombre. Algo le decía que la estufa debía ser del sexo femenino-. ¿Cómo pudiste abandonar tan buena compañía?

-Me obligaron -dijo el perro-. Me echaron a la calle y me encadenaron. Había mordido en la pierna al señorito pequeño, porque me quitó un hueso que estaba royendo. ¡Pata por pata!, éste es mi lema. Pero lo tomaron a mal, y desde entonces me paso la vida preso aquí, y he perdido mi voz sonora. Fíjate en lo ronco que estoy: ¡fuera, fuera! Y ahí tienes el fin de la canción.

El hombre de nieve ya no lo escuchaba. Fija la mirada en la vivienda del ama de llaves, contemplaba la estufa sostenida sobre sus cuatro pies de hierro, tan voluntariosa como él mismo.

-¡Qué manera de crujir este cuerpo mío! -dijo-. ¿No me dejarán entrar? Es un deseo inocente, y nuestros deseos inocentes debieran verse cumplidos. Es mi mayor anhelo, el único que tengo; sería una injusticia que no se me permitiese satisfacerlo. Quiero entrar y apoyarme en ella, aunque tenga que romper la ventana.

-Nunca entrarás allí -dijo el mastín-. ¡Apañado estarías si lo hicieras!

-Ya casi lo estoy -dijo el hombre-; creo que me derrumbo.

El hombre de nieve permaneció en su lugar todo el día, mirando por la ventana. Al anochecer, el aposento se volvió aún más acogedor. La estufa brillaba suavemente, más de lo que pueden hacerlo la luna y el sol, con aquel brillo exclusivo de las estufas cuando tienen algo dentro. Cada vez que le abrían la puerta escupía una llama; tal era su costumbre. El blanco rostro del hombre de nieve quedaba entonces teñido de un rojo ardiente, y su pecho despedía también un brillo rojizo. -¡No resisto más! -dijo-. ¡Qué bien le sienta eso de sacar la lengua!

La noche fue muy larga, pero al hombre no se lo pareció. La pasó absorto en dulces pensamientos, que se le helaron dando crujidos. Por la madrugada, todas las ventanas del sótano estaban heladas, recubiertas de las más hermosas flores que nuestro hombre pudiera soñar; sólo que ocultaban la estufa. Los cristales no se deshelaban, y él no podía ver a su amada. Crujía y rechinaba; hacía un tiempo ideal para un hombre de nieve, y, sin embargo, el nuestro no estaba contento. Debería haberse sentido feliz, pero no lo era; sentía nostalgia de la estufa.

-Es una mala enfermedad para un hombre de nieve -dijo el perro-. También yo la padecí un tiempo, pero me curé. ¡Fuera, fuera! Ahora tendremos cambio de tiempo.

Y, efectivamente, así fue. Comenzó el deshielo.

El deshielo aumentaba, y el hombre de nieve decrecía. No decía nada ni se quejaba, y éste es el más elocuente síntoma de que se acerca el fin.

Una mañana se desplomó. En su lugar quedó un objeto parecido a un palo de escoba. Era lo que había servido de núcleo a los niños para construir el muñeco.

-Ahora comprendo su anhelo -dijo el perro mastín-. El hombre tenía un atizador en el cuerpo. De ahí venía su inquietud. Ahora la ha superado. ¡Fuera, fuera!

Y poco después quedó también superado el invierno.

-¡Fuera, fuera! -ladraba el perro; pero las chiquillas, en el patio, cantaban:

Brota, asperilla, flor mensajera;
cuelga, sauce, tus lanosos mitones;
cuclillo, alondra, envíennos canciones;
febrero, viene ya la primavera.
Cantaré con ustedes
y todos se unirán al jubiloso coro.
¡Baja ya de tu cielo, oh, sol de oro!

¡Quién se acuerda hoy del hombre de nieve!`