module.exports = `Como cada verano, a la Señora Pata le dio por empollar y todas sus amigas del corral estaban deseosas de ver a sus patitos, que siempre eran los más guapos de todos.

Llegó el día en que los patitos comenzaron a abrir los huevos poco a poco y todos se congregaron ante el nido para verles por primera vez.

Uno a uno fueron saliendo hasta seis preciosos patitos, cada uno acompañado por los gritos de alborozo de la Señora Pata y de sus amigas. Tan contentas estaban que tardaron un poco en darse cuenta de que un huevo, el más grande de los siete, aún no se había abierto.

Todos concentraron su atención en el huevo que permanecía intacto, incluso los patitos recién nacidos, esperando ver algún signo de movimiento.

Al poco, el huevo comenzó a romperse y de él salió un sonriente pato, más grande que sus hermanos, pero ¡oh, sorpresa!, muchísimo más feo y desgarbado que los otros seis...

La Señora Pata se moría de vergüenza por haber tenido un patito tan feísimo y le apartó con el ala mientras prestaba atención a los otros seis.

El patito se quedó tristísimo porque se empezó a dar cuenta de que allí no le querían...

Pasaron los días y su aspecto no mejoraba, al contrario, empeoraba, pues crecía muy rápido y era flacucho y desgarbado, además de bastante torpe el pobrecito.

Sus hermanos le jugaban pesadas bromas y se reían constantemente de él llamándole feo y torpe.

El patito decidió que debía buscar un lugar donde pudiese encontrar amigos que de verdad le quisieran a pesar de su desastroso aspecto y una mañana muy temprano, antes de que se levantase el granjero, huyó por un agujero del cercado.

Así llegó a otra granja, donde una vieja le recogió y el patito feo creyó que había encontrado un sitio donde por fin le querrían y cuidarían, pero se equivocó también, porque la vieja era mala y sólo quería que el pobre patito le sirviera de primer plato. También se fue de aquí corriendo.

Llegó el invierno y el patito feo casi se muere de hambre pues tuvo que buscar comida entre el hielo y la nieve y tuvo que huir de cazadores que pretendían dispararle.

Al fin llegó la primavera y el patito pasó por un estanque donde encontró las aves más bellas que jamás había visto hasta entonces. Eran elegantes, gráciles y se movían con tanta distinción que se sintió totalmente acomplejado porque él era muy torpe. De todas formas, como no tenía nada que perder se acercó a ellas y les preguntó si podía bañarse también.

Los cisnes, pues eran cisnes las aves que el patito vio en el estanque, le respondieron:

- ¡Claro que sí, eres uno de los nuestros!

A lo que el patito respondió:

-¡No os burléis de mí!. Ya sé que soy feo y desgarbado, pero no deberíais reír por eso...

- Mira tu reflejo en el estanque -le dijeron ellos- y verás cómo no te mentimos.

El patito se introdujo incrédulo en el agua transparente y lo que vio le dejó maravillado. ¡Durante el largo invierno se había transformado en un precioso cisne!. Aquel patito feo y desgarbado era ahora el cisne más blanco y elegante de todos cuantos había en el estanque.

Así fue como el patito feo se unió a los suyos y vivió feliz para siempre.`