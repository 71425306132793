const MIN_SAMPLING = 10;

const $ppm = document.getElementById("ppm");
const $fail = document.getElementById("fails");
const $total = document.getElementById("total");
const $time = document.getElementById("time");

let timer = null;
let started = false;
let startTime = null;
let hits = 0;
let fails = 0;
let totalSeconds = 0;
let total = 0;
let ppm = 0;

function displayTime (seconds) {
  const format = val => `0${Math.floor(val)}`.slice(-2)
  const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  return [hours, minutes, seconds % 60].map(format).join(':')
}

function now() {
    return new Date();
}

function start() {
    timer = setInterval(update, 100);
}

function stop() {
    clearInterval(timer);
}

function update() {
    calcularPpm();

    $ppm.innerText = ppm;
    $fail.innerText = fails;
    $total.innerText = hits;
    $time.innerText = displayTime(totalSeconds);
}

function hit() {
    if(!started){
        startTime = now();
        started = true;
    }
    hits++;
}

function fail() {
    if(!started){
        startTime = now();
        started = true;
    }
    fails++;
}

function reset() {
    totalSeconds = 0;
    startTime = null;
    started = false;
    hits = 0;
    fails = 0;
    ppm = 0;
}

function getTotalSeconds(){
    return Math.ceil((now().getTime() - startTime.getTime()) / 1000);
}

function calcularPpm() {
    if (!started) {
        return;
    }

    total = hits + fails;
    totalSeconds = getTotalSeconds();
    ppm = Math.ceil(((total / 5) - fails) / (totalSeconds/60));
}

export default { start, stop, hit, fail, reset };
