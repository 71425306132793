module.exports = `-¡Quiero ser algo! -decía el mayor de cinco hermanos-. Quiero servir de algo en este mundo. Si ocupo un puesto, por modesto que sea, que sirva a mis semejantes, seré algo. Los hombres necesitan ladrillos. Pues bien, si yo los fabrico, haré algo real y positivo.
-Sí, pero eso es muy poca cosa -replicó el segundo hermano-. Tu ambición es muy humilde: es trabajo de peón, que una máquina puede hacer. No, más vale ser albañil. Eso sí es algo, y yo quiero serlo. Es un verdadero oficio. Quien lo profesa es admitido en el gremio y se convierte en ciudadano, con su bandera propia y su casa gremial. Si todo marcha bien, podré tener oficiales, me llamarán maestro, y mi mujer será la señora patrona. A eso llamo yo ser algo.
-¡Tonterías! -intervino el tercero-. Ser albañil no es nada. Quedarás excluido de los estamentos superiores, y en una ciudad hay muchos que están por encima del maestro artesano. Aunque seas un hombre de bien, tu condición de maestro no te librará de ser lo que llaman un «patán». No, yo seré algo mejor. Seré arquitecto, seguiré por la senda del Arte, del pensamiento, subiré hasta el nivel más alto en el reino de la inteligencia. Habré de empezar desde abajo, sí; te lo digo sin rodeos: comenzaré de aprendiz.
Llevaré gorra, aunque estoy acostumbrado a tocarme con sombrero de seda. Iré a comprar aguardiente y cerveza para los oficiales, y ellos me tutearán, lo cual no me agrada, pero imaginaré que no es sino una comedia, libertades propias del Carnaval. Mañana, es decir, cuando sea oficial, emprenderé mi propio camino, sin preocuparme de los demás. Iré a la academia a aprender dibujo, y seré arquitecto. Esto sí es algo. ¡Y mucho! Acaso me llamen señoría, y excelencia, y me pongan, además, algún título delante y detrás, y venga edificar, como otros hicieron antes que yo. Y entretanto iré construyendo mi fortuna. ¡Ese algo vale la pena!
-Pues eso que tú dices que es algo, se me antoja muy poca cosa, y hasta te diré que nada -dijo el cuarto-. No quiero tomar caminos trillados. No quiero ser un copista. Mi ambición es ser un genio, mayor que todos ustedes juntos. Crearé un estilo nuevo, levantaré el plano de los edificios según el clima y los materiales del país, haciendo que cuadren con su sentimiento nacional y la evolución de la época, y les añadiré un piso, que será un zócalo para el pedestal de mi gloria.
-¿Y si nada valen el clima y el material? -preguntó el quinto-. Sería bien sensible, pues no podrían hacer nada de provecho. El sentimiento nacional puede engreírse y perder su valor; la evolución de la época puede escapar de tus manos, como se te escapa la juventud. Ya veo que en realidad ninguno de ustedes llegará a ser nada, por mucho que lo esperen. Pero hagan lo que les plazca. Yo no voy a imitaros; me quedaré al margen, para juzgar y criticar sus obras. En este mundo todo tiene sus defectos; yo los descubriré y sacaré a la luz. Esto será algo.
Así lo hizo, y la gente decía de él: «Indudablemente, este hombre tiene algo. Es una cabeza despejada. Pero no hace nada». Y, sin embargo, por esto precisamente era algo.
Como ven, esto no es más que un cuento, pero un cuento que nunca se acaba, que empieza siempre de nuevo, mientras el mundo sea mundo.
Pero, ¿qué fue, a fin de cuentas, de los cinco hermanos? Escúchenme bien, que es toda una historia.
El mayor, que fabricaba ladrillos, observó que por cada uno recibía una monedita, y aunque sólo fuera de cobre, reuniendo muchas de ellas se obtenía un brillante escudo. Ahora bien, dondequiera que vayan con un escudo, a la panadería, a la carnicería o a la sastrería, se les abre la puerta y sólo tienen que pedir lo que les haga falta. He aquí lo que sale de los ladrillos. Los hay que se rompen o desmenuzan, pero incluso de éstos se puede sacar algo.
Una pobre mujer llamada Margarita deseaba construirse una casita sobre el malecón. El hermano mayor, que tenía un buen corazón, aunque no llegó a ser más que un sencillo ladrillero, le dio todos los ladrillos rotos, y unos pocos enteros por añadidura. La mujer se construyó la casita con sus propias manos. Era muy pequeña; una de las ventanas estaba torcida; la puerta era demasiado baja, y el techo de paja hubiera podido quedar mejor. Pero, bien que mal, la casuca era un refugio, y desde ella se gozaba de una buena vista sobre el mar, aquel mar cuyas furiosas olas se estrellaban contra el malecón, salpicando con sus gotas salobres la pobre choza, y tal como era, ésta seguía en pie mucho tiempo después de estar muerto el que había cocido los ladrillos.
El segundo hermano conocía el oficio de albañil, mucho mejor que la pobre Margarita, pues lo había aprendido tal como se debe.
Aprobado su examen de oficial, se echó la mochila al hombro y entonó la canción del artesano:
Joven yo soy, y quiero correr mundo,
e ir levantando casas por doquier,
cruzar tierras, pasar el mar profundo,
confiado en mi arte y mi valer.
Y si a mi tierra regresara un día
atraído por el amor que allí dejé,
alárgame la mano, patria mía,
y tú, casita que mía te llamé.

Y así lo hizo. Regresó a la ciudad, ya en calidad de maestro, y construyó casas y más casas, una junto a otra, hasta formar toda una calle. Terminada ésta, que era muy bonita y realzaba el aspecto de la ciudad, las casas edificaron para él una casita, de su propiedad. ¿Cómo pueden construir las casas? Pregúntaselo a ellas. Si no te responden, lo hará la gente en su lugar, diciendo: «Sí, es verdad, la calle le ha construido una casa». Era pequeña y de pavimento de arcilla, pero bailando sobre él con su novia se volvió liso y brillante; y de cada piedra de la pared brotó una flor, con lo que las paredes parecían cubiertas de preciosos tapices. Fue una linda casa y una pareja feliz. La bandera del gremio ondeaba en la fachada, y los oficiales y aprendices gritaban «¡Hurra por nuestro maestro!». Sí, señor, aquél llegó a ser algo. Y murió siendo algo.
Vino luego el arquitecto, el tercero de los hermanos, que había empezado de aprendiz, llevando gorra y haciendo de mandadero, pero más tarde había ascendido a arquitecto, tras los estudios en la Academia, y fue honrado con los títulos de Señoría y Excelencia. Y si las casas de la calle habían edificado una para el hermano albañil, a la calle le dieron el nombre del arquitecto, y la mejor casa de ella fue suya. Llegó a ser algo, sin duda alguna, con un largo título delante y otro detrás. Sus hijos pasaban por ser de familia distinguida, y cuando murió, su viuda fue una viuda de alto copete... y esto es algo. Y su nombre quedó en el extremo de la calle y como nombre de calle siguió viviendo en labios de todos. Esto también es algo, sí señor.
Siguió después el genio, el cuarto de los hermanos, el que pretendía idear algo nuevo, aparte del camino trillado, y realzar los edificios con un piso más, que debía inmortalizarle. Pero se cayó de este piso y se rompió el cuello. Eso sí, le hicieron un entierro solemnísimo, con las banderas de los gremios, música, flores en la calle y elogios en el periódico; en su honor se pronunciaron tres panegíricos, cada uno más largo que el anterior, lo cual le habría satisfecho en extremo, pues le gustaba mucho que hablaran de él. Sobre su tumba erigieron un monumento, de un solo piso, es verdad, pero esto es algo.
El tercero había muerto, pues, como sus tres hermanos mayores. Pero el último, el razonador, sobrevivió a todos, y en esto estuvo en su papel, pues así pudo decir la última palabra, que es lo que a él le interesaba. Como decía la gente, era la cabeza clara de la familia. Pero le llegó también su hora, se murió y se presentó a la puerta del cielo, por la cual se entra siempre de dos en dos. Y he aquí que él iba de pareja con otra alma que deseaba entrar a su vez, y resultó ser la pobre vieja Margarita, la de la casa del malecón.
-De seguro que será para realzar el contraste por lo que me han puesto de pareja con esta pobre alma -dijo el razonador.
-¿Quién eres, abuelita? ¿Quieres entrar también? -le preguntó.
Se inclinó la vieja lo mejor que pudo, pensando que el que le hablaba era San Pedro en persona.
-Soy una pobre mujer sencilla, sin familia, la vieja Margarita de la casita del malecón.
-Ya, ¿y qué es lo que hiciste allá abajo?
-Bien poca cosa, en realidad. Nada que pueda valerme la entrada aquí. Será una gracia muy grande de nuestro Señor, si me admiten en el Paraíso.
-¿Y cómo fue que te marchaste del mundo? -siguió preguntando él, sólo por decir algo, pues al hombre le aburría la espera.
-La verdad es que no lo sé. El último año lo pasé enferma y pobre. Un día no tuve más remedio que levantarme y salir, y me encontré de repente en medio del frío y la helada. Seguramente no pude resistirlo. Le contaré cómo ocurrió: Fue un invierno muy duro, pero hasta entonces lo había aguantado. El viento se calmó por unos días, aunque hacía un frío cruel, como nuestra Señoría debe saber.
La capa de hielo entraba en el mar hasta perderse de vista. Toda la gente de la ciudad había salido a pasear sobre el hielo, a patinar, como dicen ellos, y a bailar, y también creo que había música y merenderos. Yo lo oía todo desde mi pobre cuarto, donde estaba acostada. Esto duró hasta el anochecer. Había salido ya la luna, pero su luz era muy débil. Miré al mar desde mi cama, y entonces vi que de allí donde se tocan el cielo y el mar subía una maravillosa nube blanca. Me quedé mirándola y vi un punto negro en su centro, que crecía sin cesar; y entonces supe lo que aquello significaba -pues soy vieja y tengo experiencia-, aunque no es frecuente ver el signo. Yo lo conocí y sentí espanto. Durante mi vida lo había visto dos veces, y sabía que anunciaba una espantosa tempestad, con una gran marejada que sorprendería a todos aquellos desgraciados que allí estaban, bebiendo, saltando y divirtiéndose. Toda la ciudad había salido, viejos y jóvenes. ¡Quién podía prevenirlos, si nadie veía el signo ni se daba cuenta de lo que yo observaba! Sentí una angustia terrible, y me entró una fuerza y un vigor como hacía mucho tiempo no había sentido. Salté de la cama y me fui a la ventana; no pude ir más allá. Conseguí abrir los postigos, y vi a muchas personas que corrían y saltaban por el hielo y vi las lindas banderitas y oí los hurras de los chicos y los cantos de los mozos y mozas. Todo era bullicio y alegría, y mientras tanto la blanca nube con el punto negro iba creciendo por momentos. Grité con todas mis fuerzas, pero nadie me oyó, pues estaban demasiado lejos. La tempestad no tardaría en estallar, el hielo se resquebrajaría y haría pedazos, y todos aquellos, hombres y mujeres, niños y mayores, se hundirían en el mar, sin salvación posible. Ellos no podían oírme, y yo no podía ir hasta ellos. ¿Cómo conseguir que viniesen a tierra? Dios Nuestro Señor me inspiró la idea de pegar fuego a mí cama.
Más valía que se incendiara mi casa, a que todos aquellos infelices pereciesen. Encendí el fuego, vi la roja llama, salí a la puerta... pero allí me quedé tendida, con las fuerzas agotadas. Las llamas se agrandaban a mi espalda, saliendo por la ventana y por encima del tejado. Los patinadores las vieron y acudieron corriendo en mi auxilio, pensando que iba a morir abrasada. Todos vinieron hacia el malecón. Los oí venir, pero al mismo tiempo oí un estruendo en el aire, como el tronar de muchos cañones. La ola de marea levantó el hielo y lo hizo pedazos, pero la gente pudo llegar al malecón, donde las chispas me caían encima. Todos estaban a salvo. Yo, en cambio, no pude resistir el frío y el espanto, y por esto he venido aquí, a la puerta del cielo. Dicen que está abierta para los pobres como yo. Y ahora ya no tengo mi casa. ¿Qué le parece, me dejarán entrar?
En esto se abrió la puerta del cielo, y un ángel hizo entrar a la mujer. De ésta cayó una brizna de paja, una de las que había en su cama cuando la incendió para salvar a los que estaban en peligro. La paja se transformó en oro, pero en un oro que crecía y echaba ramas, que se trenzaban en hermosísimos arabescos.
-¿Ves? -dijo el ángel al razonador-, esto lo ha traído la pobre mujer. Y tú, ¿qué traes? Nada, bien lo sé. No has hecho nada, ni siquiera un triste ladrillo. Podrías volverte y, por lo menos, traer uno. De seguro que estaría mal hecho, siendo obra de tus manos, pero algo valdría la buena voluntad. Por desgracia, no puedes volverte, y nada puedo hacer por ti.
Entonces, aquella pobre alma, la mujer de la casita del malecón, intercedió por él:
-Su hermano me regaló todos los ladrillos y trozos con los que pude levantar mi humilde casa. Fue un gran favor que me hizo. ¿No servirían todos aquellos trozos como un ladrillo para él? Es una gracia que pido. La necesita tanto, y puesto que estamos en el reino de la gracia...
-Tu hermano, a quien tú creías el de más cortos alcances -dijo el ángel- aquél cuya honrada labor te parecía la más baja, te da su óbolo celestial. No serás expulsado. Se te permitirá permanecer ahí fuera reflexionando y reparando tu vida terrenal; pero no entrarás mientras no hayas hecho una buena acción.
-Yo lo habría sabido decir mejor -pensó el pedante, pero no lo dijo en voz alta, y esto ya es algo.`