module.exports = `Abuelita es muy vieja, tiene muchas arrugas y el pelo completamente blanco, pero sus ojos brillan como estrellas, sólo que mucho más hermosos, pues su expresión es dulce, y da gusto mirarlos. También sabe cuentos maravillosos y tiene un vestido de flores grandes, grandes, de una seda tan tupida que cruje cuando anda. Abuelita sabe muchas, muchísimas cosas, pues vivía ya mucho antes que papá y mamá, esto nadie lo duda. Tiene un libro de cánticos con recias cantoneras de plata; lo lee con gran frecuencia. En medio del libro hay una rosa, comprimida y seca, y, sin embargo, la mira con una sonrisa de arrobamiento, y le asoman lágrimas a los ojos. ¿Por qué abuelita mirará así la marchita rosa de su devocionario? ¿No lo sabes? Cada vez que las lágrimas de la abuelita caen sobre la flor, los colores cobran vida, la rosa se hincha y toda la sala se impregna de su aroma; se esfuman las paredes cual si fuesen pura niebla, y en derredor se levanta el bosque, espléndido y verde, con los rayos del sol filtrándose entre el follaje, y abuelita vuelve a ser joven, una bella muchacha de rubias trenzas y redondas mejillas coloradas, elegante y graciosa; no hay rosa más lozana, pero sus ojos, sus ojos dulces y cuajados de dicha, siguen siendo los ojos de abuelita.

Sentado junto a ella hay un hombre, joven, vigoroso, apuesto. Huele la rosa y ella sonríe - ¡pero ya no es la sonrisa de abuelita! - sí, y vuelve a sonreír. Ahora se ha marchado él, y por la mente de ella desfilan muchos pensamientos y muchas figuras; el hombre gallardo ya no está, la rosa yace en el libro de cánticos, y... abuelita vuelve a ser la anciana que contempla la rosa marchita guardada en el libro.

Ahora abuelita se ha muerto. Sentada en su silla de brazos, estaba contando una larga y maravillosa historia.

-Se ha terminado -dijo- y yo estoy muy cansada; dejadme echar un sueñito.

Se recostó respirando suavemente, y quedó dormida; pero el silencio se volvía más y más profundo, y en su rostro se reflejaban la felicidad y la paz; se habría dicho que lo bañaba el sol... y entonces dijeron que estaba muerta.

La pusieron en el negro ataúd, envuelta en lienzos blancos. ¡Estaba tan hermosa, a pesar de tener cerrados los ojos! Pero todas las arrugas habían desaparecido, y en su boca se dibujaba una sonrisa. El cabello era blanco como plata y venerable, y no daba miedo mirar a la muerta. Era siempre la abuelita, tan buena y tan querida. Colocaron el libro de cánticos bajo su cabeza, pues ella lo había pedido así, con la rosa entre las páginas. Y así enterraron a abuelita.

En la sepultura, junto a la pared del cementerio, plantaron un rosal que floreció espléndidamente, y los ruiseñores acudían a cantar allí, y desde la iglesia el órgano desgranaba las bellas canciones que estaban escritas en el libro colocado bajo la cabeza de la difunta. La luna enviaba sus rayos a la tumba, pero la muerta no estaba allí; los niños podían ir por la noche sin temor a coger una rosa de la tapia del cementerio. Los muertos saben mucho más de cuanto sabemos todos los vivos; saben el miedo, el miedohorrible que nos causarían si volviesen. Pero son mejores que todos nosotros, y por eso no vuelven. Hay tierra sobre el féretro, y tierra dentro de él. El libro de cánticos, con todas sus hojas, es polvo, y la rosa, con todos sus recuerdos, se ha convertido en polvo también. Pero encima siguen floreciendo nuevas rosas y cantando los ruiseñores, y enviando el órgano sus melodías. Y uno piensa muy a menudo en la abuelita, y la ve con sus ojos dulces, eternamente jóvenes. Los ojos no mueren nunca. Los nuestros verán a abuelita, joven y hermosa como antaño, cuando besó por vez primera la rosa, roja y lozana, que yace ahora en la tumba convertida en polvo.`