import library from "./library.js";
import stats from "./stats";

const $caption = document.getElementById("caption");
const $screen = document.getElementById("screen");
const $bookAuthor = document.getElementById("book-author");
const $status = document.getElementById("status");
const $writebox = document.getElementById("writebox");

let cursorPosition = 0;
let letters = [];
let currentBook = null;
let currentPage = 0;

function init() {
    loadBook(library.loadWelcome());
}

function restart() {
    let book = library.load();
    loadBook(book);
}

function currentChar() {
    return letters[cursorPosition].innerText;
}

function prepareContent(content) {
    return content
        .split("")
        .map(function (c) {
            return "<em>" + c + "</em>";
        })
        .join("");
}

function loadBook(book) {
    currentBook = book;
    $caption.innerText = book.title;
    $bookAuthor.innerHTML = book.author;
    loadPage(0);
}

function loadPage(num) {
    currentPage = num;
    cursorPosition = 0;
    $screen.innerHTML = prepareContent(currentBook.pages[currentPage]);
    letters = $screen.querySelectorAll("em");

    const p = currentPage + 1;
    const t = currentBook.pages.length;
    $status.innerText = `Página ${p} de ${t}`;
}

function nextPage() {
    if (currentPage < currentBook.pages.length - 1) {
        loadPage(currentPage + 1);
    } else {
        restart();
    }
}

function resetText() {
    cursorPosition = 0;
    moveToEm(letters[0]);
}

function moveToEm(em) {
    cursorPosition = Array.from(letters).indexOf(em);
    letters.forEach(function (item, index) {
        if (index < cursorPosition) {
            item.classList.add("ok");
        } else {
            item.classList.remove("ok");
        }
    });
}

function ensureView() {
    letters[cursorPosition].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
    });
}

function movePrev() {
    if (cursorPosition > 0) {
        cursorPosition--;
        letters[cursorPosition].classList.remove("ok");
    }

    ensureView();
}

function moveNext() {
    letters[cursorPosition].classList.add("ok");
    if (cursorPosition < letters.length - 1) {
        cursorPosition++;
    } else {
        nextPage();
    }

    ensureView();
}

function getCurrent() {
    return cursorPosition;
}

function preventDefault(event) {
    event.preventDefault();
}

function sameLeter(key, currentChar) {
    if (key == currentChar) {
        return true;
    }

    if (key == "-") {
        if (currentChar.codePointAt(0) == 8211 || currentChar == "—") {
            return true;
        }
    }

    return false;
}

function specialKeyPressed(event) {
    return event.altKey || event.ctrlKey || event.shiftKey || event.metaKey;
}

function handleValidKey(event) {
    if (sameLeter(event.data, currentChar())) {
        moveNext();
        stats.hit();
    } else {
        stats.fail();
    }
}

function wbOnInput(event) {
    handleValidKey(event);
}

function wbOnKeydown(event) {
    switch (event.key) {
        case "Backspace":
            event.preventDefault();
            movePrev();
            break;
        case "Escape":
            event.preventDefault();
            resetText();
            stats.reset();
            break;
        case "Enter":
            event.preventDefault();
            restart();
            stats.reset();
            break;
        default:
            if(event.repeat){
                event.preventDefault();
                return false;
            }
            break;
    }
}

function screenOnClick(event) {
    if (specialKeyPressed(event)) {
        return;
    }

    event.preventDefault();

    if (event.repeat) {
        return;
    }

    if (event.target.tagName == "EM") {
        moveToEm(event.target);
        stats.reset();
    }

    focus();
}

function onClickOutside(event) {
    if (!event.isDefaultPrevented) {
        event.preventDefault();
        focus();
    }
}

function focus() {
    $writebox.focus();
}

function setupHandlers() {
    $writebox.addEventListener("cut", preventDefault);
    $writebox.addEventListener("copy", preventDefault);
    $writebox.addEventListener("paste", preventDefault);
    $writebox.addEventListener("input", wbOnInput);
    $writebox.addEventListener("keydown", wbOnKeydown);
    $screen.addEventListener("click", screenOnClick);
    document.addEventListener("click", onClickOutside);
}

function setup() {
    setupHandlers();
    stats.start();
    init();
    focus();
}

export default { setup };
