module.exports = `Una mañana de primavera todos los miembros de un rebaño se despertaron sobresaltados a causa de unos sonidos fuertes y secos que provenían del exterior del establo. Salieron en tropel a ver qué sucedía y se toparon con una pelea en la que dos carneros situados frente a frente estaban haciendo chocar sus duras cornamentas.

Un gracioso corderito muy fanático de los chismes fue el primero en enterarse de los motivos y corrió a informar al grupo. Según sus fuentes, que eran totalmente fiables, se estaban disputando el amor de una oveja muy linda que les había robado el corazón.

– Por lo visto está coladita por los dos, y como sabía a cuál elegir, anoche declaró que se casaría con el más forzudo. El resto de la historia os la podéis imaginar: los carneros se enteraron, quedaron para retarse antes del amanecer y… bueno, ahí tenéis a los amigos, ahora rivales, enzarzados en un combate.

El jefe del rebaño, un carnero maduro e inteligente al que nadie se atrevía a cuestionar, exclamó:

– ¡Serenaos! No es más que una de las muchas peloteras románticas que se forman todos los años en esta granja. Sí, se pelean por una chica, pero ya sabemos que no se hacen daño y que gane quien gane seguirán siendo colegas. ¡Nos quedaremos a ver el desenlace!

Los presentes respiraron tranquilos al saber que solo se trataba de un par de jóvenes enamorados compitiendo por una blanquísima ovejita; una ovejita que, por cierto, lo estaba presenciando todo con el corazón encogido y conteniendo la respiración. ¿Quién se alzaría con la victoria? ¿Quién se convertiría en su futuro marido?… ¡La suerte estaba echada!

————–

Esta era la situación cuando un gallo de colores al que nadie había visto antes se coló entre los asistentes y se sentó en primera fila como si fuera un invitado de honor. Jamás había sido testigo de una riña entre carneros, pero como se creía el tipo más inteligente del mundo y adoraba ser el centro de atención,  se puso a opinar a voz en grito demostrando muy mala educación.

– ¡Ay madre, vaya birria de batalla!… ¡Estos carneros son más torpes que una manada de elefantes dentro de una cacharrería!

Inmediatamente se oyeron murmullos de desagrado entre el público, pero él se hizo el sordo y continuó soltando comentarios fastidiosos e inoportunos.

– ¡Dicen por aquí que se trata de un duelo entre caballeros, pero la verdad es que yo solo veo dos payasos haciendo bobadas!… ¡Eh, espabilad chavales, que ya sois mayorcitos para hacer el ridículo!

Los murmullos subieron de volumen y algunos le miraron de reojo para ver si se daba por aludido y cerraba el pico; de nuevo, hizo caso omiso y siguió con su crítica feroz.

– Aunque el carnero de la derecha es un poco más ágil, el de la izquierda tiene los cuernos más grandes… ¡Creo que la oveja debería casarse con ese para que sus hijos nazcan fuertes y robustos!

Los espectadores le miraron alucinados. ¿Cómo se podía ser tan desconsiderado?

– Aunque para ser honesto, no entiendo ese empeño en casarse con la misma. ¡A mí me parece que la oveja en cuestión no es para tanto!

Los carneros, ovejas y corderos enmudecieron y se hizo un silencio sobrecogedor. Sus caras de indignación hablaban por sí solas. El jefe de clan pensó que, definitivamente, se había pasado de la raya. En nombre de la comunidad, tomó la palabra.

– ¡Un poco de respeto, por favor!… ¡¿Acaso no sabes comportarte?!

– ¿Yo?  ¿Qué si sé comportarme yo?… ¡Solo estoy diciendo la verdad! Esa oveja es idéntica a las demás, ni más fea, ni más guapa, ni más blanca… ¡No sé por qué pierden el tiempo luchando por ella habiendo tantas para escoger!

– ¡Cállate mentecato, ya está bien de decir tonterías!

El gallo puso cara de sorpresa y respondió con chulería:

– ¡¿Qué me calle?!… ¡Porque tú lo digas!

El jefe intentó no perder los nervios. Por nada del mundo quería que se calentaran los ánimos y se montara una bronca descomunal.

– A ver, vamos a calmarnos un poco los dos. Tú vienes de lejos, ¿verdad?

– Sí, soy forastero, estoy de viaje. Venía por el camino de tierra que rodea el trigal y al pasar por delante de la valla escuché jaleo y me metí a curiosear.

– Entiendo entonces que como vives en otras tierras es la primera vez que estás en compañía de individuos de nuestra especie…  ¿Me equivoco?

El gallo, desconcertado, respondió:

– No, no te equivocas, pero… ¿eso qué tiene que ver?

– Te lo explicaré con claridad: tú no tienes ningún derecho a entrometerte en nuestra comunidad y burlarte de nuestro comportamiento por la sencilla razón de que no nos conoces.

– ¡Pero es que a mí me gusta decir lo que pienso!

– Vale, eso está muy bien y por supuesto es respetable, pero antes de dar tu opinión deberías saber cómo somos y cuál es nuestra forma de relacionarnos.

– ¿Ah, sí? ¿Y cuál es, si se puede saber?

– Bueno, pues un ejemplo es lo que acabas de presenciar.  En nuestra especie, al igual que en muchas otras, las peleas entre machos de un mismo rebaño son habituales en época de celo porque es cuando toca elegir pareja. Somos animales pacíficos y de muy buen carácter, pero ese ritual forma parte de nuestra forma de ser, de nuestra naturaleza.

– Pero…

– ¡No hay pero que valga! Debes comprender que para nosotros estas conductas son completamente normales. ¡No podemos luchar contra miles de años de evolución y eso hay que respetarlo!

El gallo empezó a sentir el calor que la vergüenza producía en su rostro. Para que nadie se diera cuenta del sonrojo, bajó la cabeza y clavó la mirada en el suelo.

– Tú sabrás mucho sobre gallos, gallinas, polluelos, nidos y huevos, pero del resto no tienes ni idea ¡Vete con los tuyos y deja que resolvamos las cosas a nuestra manera!

El gallo tuvo que admitir que se había pasado de listillo y sobre todo, de grosero, así que si no quería salir mal parado debía largarse cuanto antes.  Echó un último vistazo a los carneros, que ahí seguían a lo suyo, peleándose por el amor de la misma hembra, y sin ni siquiera decir adiós se fue para nunca más volver.

Moraleja: Todos tenemos derecho a expresar nuestros pensamientos con libertad, claro que sí, pero a la hora de dar nuestra opinión es importante hacerlo con sensatez. Uno no debe juzgar cosas que no conoce y mucho menos si es para ofender o despreciar a los demás.`