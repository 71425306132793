module.exports = `Un asno y un caballo vivían juntos desde su más tierna infancia y, como buenos amigos que eran, utilizaban el mismo establo, compartían la bandeja de heno, y se repartían el trabajo equitativamente. Su dueño era molinero, así que su tarea diaria consistía en transportar la harina de trigo desde el campo al mercado principal de la ciudad.

La rutina era la misma todas las mañanas: el hombre colocaba un enorme y pesado saco sobre el lomo del asno, y minutos después, otro igual de enorme y pesado sobre el lomo del caballo. En cuanto todo estaba preparado los tres abandonaban el establo y se ponían en marcha. Para los animales el trayecto era aburrido y bastante duro, pero como su sustento dependía de cumplir órdenes sin rechistar, ni se les pasaba por la mente quejarse de su suerte.

Un día, no se sabe por qué razón, el amo decidió poner dos sacos sobre el lomo de asno y ninguno sobre el lomo del caballo. Lo siguiente que hizo fue dar la orden de partir.

- ¡Arre, caballo! ¡Vamos, borrico!… ¡Daos prisa o llegaremos tarde!

Se adelantó unos metros y ellos fueron siguiendo sus pasos, como siempre perfectamente sincronizados.  Mientras caminaban, por primera vez desde que tenía uso de razón, el asno se lamentó:

- ¡Ay, amigo, fíjate en qué estado me encuentro! Nuestro dueño puso todo el peso sobre mi espalda y creo que es injusto. ¡Apenas puedo sostenerme en pie y me cuesta mucho respirar!

El pequeño burro tenía toda la razón: soportar esa carga era imposible para él. El caballo, en cambio, avanzaba a su lado ligero como una pluma y sintiendo la perfumada brisa de primavera peinando su crin. Se sentía tan dichoso, le invadía una sensación de libertad tan grande, que ni se paró a pensar en el sufrimiento de su colega. A decir verdad, hasta se sintió molesto por el comentario.

- Sí amiguete, ya sé que hoy no es el mejor día de tu vida, pero… ¡¿qué puedo hacer?!… ¡Yo no tengo la culpa de lo que te pasa!

Al burro le sorprendió la indiferencia y poca sensibilidad de su compañero de fatigas, pero estaba tan agobiado que se atrevió a pedirle ayuda.

- Te ruego que no me malinterpretes, amigo mío. Por nada del mundo quiero fastidiarte, pero la verdad es que me vendría de perlas que me echaras una mano. Me conoces y sabes que no te lo pediría si no fuera absolutamente necesario.

El caballo dio un respingo y puso cara de sorpresa.

- ¡¿Perdona?!… ¡¿Me lo estás diciendo en serio?!

El asno, ya medio mareado, pensó  que estaba en medio de una pesadilla.

- 'No, esto no puede ser real… ¡Seguro que estoy soñando y pronto despertaré!'

El sudor empezó a caerle a chorros por el pelaje y notó que sus grandes ojos almendrados  empezaban a girar cada uno hacia un lado, completamente descontrolados. Segundos después todo se volvió borroso y se quedó prácticamente sin energía. Tuvo que hacer un esfuerzo descomunal para seguir pidiendo auxilio.

- Necesito que me ayudes porque yo… yo no puedo, amigo, no puedo continuar… Yo me… yo… ¡me voy a desmayar!

El caballo resopló con fastidio.

- ¡Bah, venga, no te pongas dramático que tampoco es para tanto! Te recuerdo que eres más joven que yo y estás en plena forma. Además, para un día que me libro de cargar no voy a llevar parte de lo tuyo. ¡Sería un tonto redomado si lo hiciera!

Bajo el sol abrasador al pobre asno se le doblaron las patas como si fueran de gelatina.

- ¡Ayuda… ayuda… por favor!

Fueron sus últimas palabras antes de derrumbarse sobre la hierba.

¡Blooom!

El dueño, hasta ese momento ajeno a todo lo que ocurría tras de sí, escuchó el ruido sordo que hizo el animal al caer. Asustado se giró y vio al burro inmóvil, tirado con la panza hacia arriba y la lengua fuera.

- ¡Oh, no, mi querido burro se ha desplomado!… ¡Pobre animal! Tengo que llevarlo a la granja y avisar a un veterinario lo antes posible, pero  ¿cómo puedo hacerlo?

Hecho un manojo de nervios miró a su alrededor y detuvo la mirada sobre el caballo.

- ¡Ahora que lo pienso te tengo a ti! Tú serás quien me ayude en esta difícil situación. ¡Venga, no perdamos tiempo, agáchate!

El desconcertado caballo obedeció y se tumbó en el suelo. Entonces, el hombre colocó sobre su lomo los dos sacos de harina, y seguidamente arrastró al burro para acomodarlo también sobre la montura. Cuando tuvo todo bien atado le dio unas palmaditas cariñosas en el cuello.

- ¡Ya puedes ponerte en pie!

El animal puso cara de pánico ante lo que se avecinaba.

- Sí, ya sé que es muchísimo peso para ti, pero si queremos salvar a nuestro amigo solo podemos  hacerlo de esta manera. ¡Prometo que te recompensaré con una buena ración de forraje!

El caballo soltó un relincho que sonó a quejido, pero de nada  sirvió. Le gustara o no, debía  realizar la ruta de regreso a casa con un cargamento descomunal sobre la espalda.

Gracias a la rápida decisión del molinero llegaron a tiempo de que el veterinario pudiera reanimar al burro y dejarlo como nuevo en pocas horas. El caballo, por el contrario, se quedó tan hecho polvo, tan dolorido y tan débil,  que tardó tres semanas en recuperarse. Un tiempo muy duro en el que también lo pasó mal a nivel emocional porque se sentía muy culpable. Tumbado sobre el heno del establo lloriqueaba y repetía sin parar:

- Por mi mal comportamiento casi pierdo al mejor amigo que tengo…  ¿Cómo he podido portarme así con él?… ¡Tenía que haberle ayudado!… ¡Tenía que haberle ayudado desde el principio!

Por eso, cuando se reunieron de nuevo, con mucha humildad le pidió perdón y le prometió que jamás volvería a suceder. El burro, que era un buenazo y le quería con locura, aceptó las disculpas y lo abrazó más fuerte que nunca.

Moraleja: Esta fábula nos enseña lo importante que es cuidar, respetar y acompañar a las personas que amamos no solo en los buenos tiempos, sino también cuando atraviesan un mal   momento en su vida. No olvides nunca el sabio refrán español: 'Hoy por ti, mañana por mí'.
`