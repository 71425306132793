module.exports = `En una amplia pradera crecía una encina que cada día daba las gracias a la madre naturaleza por los muchos dones que había recibido. Tantos eran que se consideraba a sí misma como el árbol perfecto.

De todas sus cualidades una de las que más valoraba era la de ser alta, ya que le permitía no perderse ni un detalle de lo que sucedía a su alrededor. También se sentía muy satisfecha por haber nacido hermosa, y siempre que tenía ocasión, presumía de su recortada copa formada por multitud de brillantes hojas verdes.  Sí, era esbelta, guapa, y además gozaba de una salud envidiable para producir cientos de riquísimas bellotas al llegar el otoño, pero puesta a elegir, lo que más le gustaba de sí misma era su enorme y grueso tronco que le hacían sentirse fuerte, segura e imbatible.

Tener tantos atributos tuvo con el paso del tiempo una consecuencia negativa: la encina empezó a creerse superior al resto de las plantas y comenzó a comportarse de manera insolente, especialmente con las que consideraba más débiles.

———

Unos metros más abajo de donde vivía, en un pequeño humedal, habitaba un joven y delicado junco. A diferencia de su vecina era muy fino, y como no tenía ni hojas ni flores, pasaba totalmente desapercibido a ojos de los demás.

Un día, la encina se dio cuenta de su existencia y empezó a meterse con él.
– ¡Eh, junco!… ¿Qué se siente cuando uno es frágil e insignificante?

El pobre se quedó perplejo ante una pregunta tan desagradable.

– Bueno, pues no tengo mucho que decir salvo que vivo tranquilo y contento.

Al escuchar la contestación, la encina empezó a reírse con desprecio.

– ¡Ja ja ja! Desde luego con poco te conformas. No entiendo cómo se puede ser feliz rodeado de tanta humedad, plantado en ese lodo negro y pegajoso. ¡Puaj, qué asco!

El junco le respondió con humildad.
– La verdad es que me habría gustado más haber nacido en la pradera como tú, pero como bien sabes soy una planta acuática y necesito estar permanentemente en el agua para poder crecer.

La encina soltó otra risotada y siguió burlándose.

– ¡Ja ja ja! ¿Crecer?… ¡Pero si mides menos de medio metro!  Mírame a mí: yo sí soy un árbol estilizado, bello, y… ¿te has fijado en mi poderoso tronco? ¡Alucinante! ¿verdad?   Tú, en cambio, eres flacucho como un alambre. ¡Ay, qué vida tan miserable te ha tocado vivir!

El junco sabía de sobra que no era el más forzudo del lugar, pero tenía muy claro que eso no le hacía peor que nadie.

– Sí, soy bajito y delgado, pero tengo dignidad y una virtud que tú no tienes.

La encina dio un respingo y preguntó en tono socarrón.

– ¡No me digas!… ¿Y se puede saber cuál es, listillo?

– ¡Pues que soy muy flexible!
La encina estalló en carcajadas.

– ¡Ay, qué risa, esa sí que es buena!… ¡Flexible!… ¿Y de qué te sirve eso, si se puede saber? Perdona, pero ser así de blando es horrible, todo el día moviéndote de un lado a otro como un tentetieso y doblándote cada vez que sopla una ligera brisa… ¡Qué mareo y qué tortura!

– Bueno, pero en determinadas situaciones puede ser muy positivo…

– ¡¿Positivo?!… ¡Positivo es tener un  tronco grande y bien plantado como el que tengo yo!

Apenas sonaron estas palabras el cielo se oscureció, se cubrió de nubes, y estalló una de esas tremendas tormentas que aparecen cuando nadie las espera. Como es lógico todos los animales corrieron a ponerse a cubierto para protegerse de la lluvia, el viento y los relámpagos, pero las plantas y los árboles no pudieron escapar. Su única opción era limitarse a resistir y esperar a que escampara.

Desgraciadamente, sucedió lo peor: el aire enfurecido se transformó en un huracán descomunal que arrancó de cuajo la encina de la pradera y la lanzó sin piedad al fondo de un acantilado. Ni su belleza, ni su altura, ni su enorme tronco, sirvieron de nada cuando se presentó el ciclón.

El pobre junco también sufrió muchísimo y soportó como pudo el azote de la tempestad: se retorció, se balanceó de un lado a otro y sufrió graves daños, pero gracias a su enorme flexibilidad,  sobrevivió.

Una vez pasado el peligro lo primero que hizo fue mirar su maltrecho tallo de arriba abajo y quejarse de dolor.

– ¡Ay, estoy lleno de moratones y tengo algunas raíces rotas!

Pero enseguida levantó la mirada, observó el agujero donde durante años había estado la encina, y reflexionó:

– Lo que los demás ven como un defecto a mí me hace sentir orgulloso e incluso me ha salvado la vida.

Moraleja:

Todos tenemos cualidades que nos distinguen y nos hacen especiales. Siéntete a gusto con ellas y saca partido de tus talentos, pero nunca menosprecies a los demás por no ser como tú.`