module.exports = `Sí, dentro de mil años la gente cruzará el océano, volando por los aires, en alas del vapor. Los jóvenes colonizadores de América acudirán a visitar la vieja Europa. Vendrán a ver nuestros monumentos y nuestras decaídas ciudades, del mismo modo que nosotros peregrinamos ahora para visitar las decaídas magnificencias del Asia Meridional. Dentro de mil años, vendrán ellos.

El Támesis, el Danubio, el Rin, seguirán fluyendo aún; el Montblanc continuará enhiesto con su nevada cumbre, la auroras boreales proyectarán sus brillantes resplandores sobre las tierras del Norte; pero una generación tras otra se ha convertido en polvo, series enteras de momentáneas grandezas han caído en el olvido, como aquellas que hoy dormitan bajo el túmulo donde el rico harinero, en cuya propiedad se alza, se mandó instalar un banco para contemplar desde allí el ondeante campo de mieses que se extiende a sus pies.

-¡A Europa! -exclamarán las jóvenes generaciones americanas-. ¡A la tierra de nuestros abuelos, la tierra santa de nuestros recuerdos y nuestras fantasías! ¡A Europa!

Llega la aeronave, llena de viajeros, pues la travesía es más rápida que por el mar; el cable electromagnético que descansa en el fondo del océano ha telegrafiado ya dando cuenta del número de los que forman la caravana aérea. Ya se avista Europa, es la costa de Irlanda la que se vislumbra, pero los pasajeros duermen todavía; han avisado que no se les despierte hasta que estén sobre Inglaterra. Allí pisarán el suelo de Europa, en la tierra de Shakespeare, como la llaman los hombres de letras; en la tierra de la política y de las máquinas, como la llaman otros. La visita durará un día: es el tiempo que la apresurada generación concede a la gran Inglaterra y a Escocia.

El viaje prosigue por el túnel del canal hacia Francia, el país de Carlomagno y de Napoleón. Se cita a Molière, los eruditos hablan de una escuela clásica y otra romántica, que florecieron en tiempos remotos, y se encomia a héroes, vates y sabios que nuestra época desconoce, pero que más tarde nacieron sobre este cráter de Europa que es París.

La aeronave vuela por sobre la tierra de la que salió Colón, la cuna de Cortés, el escenario donde Calderón cantó sus dramas en versos armoniosos; hermosas mujeres de negros ojos viven aún en los valles floridos, y en estrofas antiquísimas se recuerda al Cid y la Alhambra. Surcando el aire, sobre el mar, sigue el vuelo hacia Italia, asiento de la vieja y eterna Roma. Hoy está decaída, la Campagna es un desierto; de la iglesia de San Pedro sólo queda un muro solitario, y aún se abrigan dudas sobre su autenticidad.

Y luego a Grecia, para dormir una noche en el lujoso hotel edificado en la cumbre del Olimpo; poder decir que se ha estado allí, viste mucho. El viaje prosigue por el Bósforo, con objeto de descansar unas horas y visitar el sitio donde antaño se alzó Bizancio. Pobres pescadores lanzan sus redes allí donde la leyenda cuenta que estuvo el jardín del harén en tiempos de los turcos.

Continúa el itinerario aéreo, volando sobre las ruinas de grandes ciudades que se levantaron a orillas del caudaloso Danubio, ciudades que nuestra época no conoce aún; pero aquí y allá -sobre lugares ricos en recuerdos que algún día saldrán del seno del tiempo- se posa la caravana para reemprender muy pronto el vuelo.

Al fondo se despliega Alemania -otrora cruzada por una densísima red de ferrocarriles y canales- el país donde predicó Lutero, cantó Goethe y Mozart empuñó el cetro musical de su tiempo. Nombres ilustres brillaron en las ciencias y en las artes, nombres que ignoramos. Un día de estancia en Alemania y otro para el Norte, para la patria de Örsted y Linneo, y para Noruega, la tierra de los antiguos héroes y de los hombres eternamente jóvenes del Septentrión. Islandia queda en el itinerario de regreso; el géiser ya no bulle, y el Hecla está extinguido, pero como la losa eterna de la leyenda, la prepotente isla rocosa sigue incólume en el mar bravío.

-Hay mucho que ver en Europa -dice el joven americano- y lo hemos visto en ocho días. Se puede hacer muy bien, como el gran viajero -aquí se cita un nombre conocido en aquel tiempo- ha demostrado en su famosa obra: Cómo visitar Europa en ocho días.

`