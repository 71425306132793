var books = [
    {
        title: "El asno y el caballo",
        author: "Cristina Rodríguez Lomba",
        content: require("/books/asno-y-caballo.txt"),
    },
    {
        title: "Los carneros y el gallo",
        author: "Cristina Rodríguez Lomba",
        content: require("/books/carneros-y-gallo.txt"),
    },
    {
        title: "Caperucita roja",
        author: "Cristina Rodríguez Lomba",
        content: require("/books/caperucita-roja.txt"),
    },
    {
        title: "La encina y el junco",
        author: "Cristina Rodríguez Lomba",
        content: require("/books/la-encina-y-el-junco.txt"),
    },
    {
        title: "El patito feo",
        author: "Hans Christian Andersen",
        content: require("/books/patito-feo.txt"),
    },
    {
        title: "Abuelita",
        author: "Hans Christian Andersen",
        content: require("/books/abuelita.txt"),
    },
    {
        title: "Algo",
        author: "Hans Christian Andersen",
        content: require("/books/algo.txt"),
    },
    {
        title: "Cada cosa en su sitio",
        author: "Hans Christian Andersen",
        content: require("/books/cada-cosa-en-su-sitio.txt"),
    },
    {
        title: "Dentro de mil años",
        author: "Hans Christian Andersen",
        content: require("/books/dentro-de-mil-anios.txt"),
    },
    {
        title: "El caracol y el rosal",
        author: "Hans Christian Andersen",
        content: require("/books/el-caracol-y-el-rosal.txt"),
    },
    {
        title: "El hombre de nieve",
        author: "Hans Christian Andersen",
        content: require("/books/el-hombre-de-nieve.txt"),
    },
];

var welcome = {
    title: "Bienvenidos a mecanito.es",
    author: "mecanito.es",
    content: `
        En esta página podrás practicar mecanografía, basta con empezar a
        escribir el texto que hay dentro de este recuadro de color azul.
        ¡Recuerda que debes escribir también las mayúsculas y las tildes!
        Pulsa INTRO para pasar de página o termina de escribir este texto.
    `,
};

function chunkString(str, len) {
    var input = str.trim().split(" ");
    var [index, output] = [0, []];
    output[index] = "";
    input.forEach((word) => {
        var temp = `${output[index]} ${word}`.trim();
        if (temp.length <= len) {
            output[index] = temp;
        } else {
            index++;
            output[index] = word;
        }
    });
    return output;
}

function normalizeContent(t) {
    t = t.replace("–", "-");
    t = t.replace("’", "'");
    t = t.replace("‘", "'");
    t = t.replace(/\u2026/g, "...");
    t = t.replace(/[\s]+/gu, " ");

    return t.trim();
}

function splitIntoPages(content) {
    return chunkString(content, 800);
}

function load(book) {
    if (!book) {
        book = pickRandom(books);
    }

    var pages = splitIntoPages(normalizeContent(book.content));

    return {
        title: book.title,
        author: book.author,
        link: book.link,
        pages: pages,
    };
}

function loadWelcome() {
    return load(welcome);
}

export default { load, loadWelcome };
